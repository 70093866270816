import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer'
import { styles } from './pdf-form-styles'

interface Props {
  serviceActivation: any
  serviceActivationId: string
  activationPsp: any
  createdAt: Date
}

const ImmunoActivationFormPDF: React.FC<Props> = ({
  serviceActivationId,
  serviceActivation,
  activationPsp,
  createdAt,
}) => (
  <Document>
    <Page style={styles.body}>
      <View>
        <Text style={styles.title}>PROGRAMMA DI SUPPORTO AL PAZIENTE IN {activationPsp.shortName.toUpperCase()}</Text>
        <Text style={styles.undertitle}>Scheda di Attivazione dei Servizi</Text>
        <Text style={styles.text}>
          La presente scheda è stata compilata dal Medico Specialista e trasmessa ad Egg S.r.l. al fine di consentire
          l’attivazione dei Servizi per i Pazienti che aderiscono al {activationPsp.name}.
        </Text>
      </View>
      <View style={{ textAlign: 'center', marginTop: 4, marginBottom: 4 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
          Codice attivazione: <Text>{serviceActivationId}</Text>
        </Text>
        {serviceActivation.patientLocality !== '' && (
          <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
            Comune domicilio Paziente: <Text>{serviceActivation.patientLocality}</Text>
          </Text>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di training all'autosomministrazione:{' '}
          {serviceActivation.trainingService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.trainingService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Si richiede che i primi training vengano svolti presso il Centro? </Text>
              <Text style={styles.value}>
                {serviceActivation.firstTrainingsAtCenter
                  ? 'Si, si richiedono i primi ' + serviceActivation.numberOfTrainingsAtCenter + ' al Centro'
                  : 'No'}
              </Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Propensione del Paziente (o del suo Caregiver) a diventare autonomo nell’auto infusione sottocutanea di
                immunoglobuline:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.patientAutonomyExpectation || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Indicare eventuali elementi che potrebbero influire positivamente o negativamente sulla capacità del
                Paziente o del Caregiver di raggiungere un buon livello di autonomia entro i 6 training previsti dal
                Servizio:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.trainingAutonomyNote || '-'}</Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di somministrazione:{' '}
          {serviceActivation.infusionService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di consegna domiciliare del farmaco:{' '}
          {serviceActivation.drugDeliveryService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.drugDeliveryService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Farmacia per il ritiro: </Text>
              <Text style={styles.value}>
                {serviceActivation.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti'
                  ? serviceActivation.pharmacyInformations
                  : serviceActivation.pharmacyOptions}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>Informazioni sul Piano Terapeutico</Text>
        {(serviceActivation.trainingService || serviceActivation.infusionService) && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Diagnosi - indicare la patologia: </Text>
              <Text style={styles.value}>{serviceActivation.diagnosys || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Indicare il tipo di immunodeficienza: </Text>
              <Text style={styles.value}>{serviceActivation.immunodeficiencyType || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Farmaco: </Text>
              <Text style={styles.value}>{serviceActivation.drugName || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Modello Pompa Infusionale: </Text>
              <Text style={styles.value}>{serviceActivation.pumpModel || '-'}</Text>
            </View>

            {serviceActivation.pumpModel === 'Altro' && (
              <View style={styles.field}>
                <Text style={styles.label}>Specificare il modello di Pompa Infusionale: </Text>
                <Text style={styles.value}>{serviceActivation.pumpModelOther || '-'}</Text>
              </View>
            )}

            <View style={styles.field}>
              <Text style={styles.label}>Ramp-up - dose 1° somministrazione: </Text>
              <Text style={styles.value}>{serviceActivation.rampupFirstDose || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Ramp-up - data o tempo di intervallo della 1° somministrazione: </Text>
              <Text style={styles.value}>{serviceActivation.rampupFirstTiming || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Ramp-up - dose 2° somministrazione: </Text>
              <Text style={styles.value}>{serviceActivation.rampupSecondDose || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Ramp-up - data o tempo di intervallo della 2° somministrazione: </Text>
              <Text style={styles.value}>{serviceActivation.rampupSecondTiming || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Ramp-up - dose 3° somministrazione: </Text>
              <Text style={styles.value}>{serviceActivation.rampupThirdDose || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Ramp-up - data o tempo di intervallo della 3° somministrazione: </Text>
              <Text style={styles.value}>{serviceActivation.rampupThirdTiming || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Ulteriori indicazioni relative al ramp-up: </Text>
              <Text style={styles.value}>{serviceActivation.rampupOtherIndications || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Dose di mantenimento: </Text>
              <Text style={styles.value}>{serviceActivation.maintenanceDose || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Frequenza di somministrazione durante il mantenimento: </Text>
              <Text style={styles.value}>{serviceActivation.maintenanceFrequency || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Velocità di infusione durante il mantenimento: </Text>
              <Text style={styles.value}>{serviceActivation.maintenanceVelocity || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Indicare il quadro clinico, eventuali patologie e indicazioni sanitarie che devono essere prese in
                considerazione prima, durante e dopo la somministrazione:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.sanitaryInformations || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso del farmaco indicato nella
                sezione "Piano Terapeutico"{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.sanitaryInformationsPreTherapy ? 'Si' : 'No'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Nel caso in cui una somministrazione non possa essere effettuata nel giorno previsto dal piano
                terapeutico e quindi subisca uno spostamento, desidera essere informato:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.visitDateChangeAlertOption || '-'}</Text>
            </View>

            {serviceActivation.visitDateChangeAlertOption ===
              'Essere avvisato se si esce da un range temporale (specificare sotto)' && (
              <View style={styles.field}>
                <Text style={styles.label}>
                  N. di giorni prima la somministrazione per cui si chiede di essere avvisati:{' '}
                </Text>
                <Text style={styles.value}>{serviceActivation.visitDateChangeAlertDaysBefore || '-'}</Text>
              </View>
            )}

            {serviceActivation.visitDateChangeAlertOption ===
              'Essere avvisato se si esce da un range temporale (specificare sotto)' && (
              <View style={styles.field}>
                <Text style={styles.label}>
                  N. di giorni dopo la somministrazione per cui si chiede di essere avvisati{' '}
                </Text>
                <Text style={styles.value}>{serviceActivation.visitDateChangeAlertDaysAfter || '-'}</Text>
              </View>
            )}

            <View style={styles.field}>
              <Text style={styles.label}>Indicare le modalità con cui desidera essere informato: </Text>
              <Text style={styles.value}>{serviceActivation.visitDateChangeAlertType || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Desidera essere contattato telefonicamente dopo la prima attività al domicilio del Paziente per ricevere
                un feedback dall’Infermiere?{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.phoneCallAfterFirstVisit ? 'Si' : 'No'}</Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.fieldNoSection}>
        <Text style={styles.label}>
          Richiedo di essere informato via email quando il Paziente completerà l'adesione:
        </Text>
        <Text style={styles.value}>{serviceActivation.requestFollowupOnPatientEnrollment ? 'Si' : 'No'}</Text>
      </View>

      <View>
        <Text style={styles.text}>
          Io sottoscritto/a, in qualità di Medico Specialista iscritto al {activationPsp.name}, ricevuta richiesta da
          parte del mio Paziente di attivare i servizi inclusi nel Programma, confermo che il Paziente è idoneo a
          ricevere i servizi qui selezionati secondo quanto previsto dalla Scheda Tecnica di Prodotto ed alle
          indicazioni sopra fornitevi. Sono inoltre consapevole che i servizi sopra indicati possono essere attivati
          solo per i Pazienti che hanno già iniziato la terapia con {`HyQvia`} e che tali servizi sono da considerarsi
          come addizionali e non sostitutivi a quelli in capo all'Ente o al SSN.
        </Text>
      </View>
      <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Text style={styles.text}>
          Data di compilazione: {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
        </Text>
      </View>
    </Page>
  </Document>
)

export default ImmunoActivationFormPDF
