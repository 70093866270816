import firebaseApp from '../firebase'
import React from 'react'
import { Psp, PspData, SubscriptionData } from '../types'
import Loading from './loading'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import ServiceActivationFormPDFDownloadButtonContainer from './service-activation-foms-pdf/service-activation-form-pdf-download-button-container'

interface ServiceActivationData {
  subscriptionId: string
  createdAt: Date
}

const ServiceActivationsTable: React.FC<{}> = () => {
  const [serviceActivations, setServiceActivations] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    firebaseApp.app
      .firestore()
      .collection('psps')
      .get()
      .then((psp) => {
        const psps: Psp[] = psp.docs.map((p) => ({ ...(p.data() as PspData), id: p.id }))
        firebaseApp.app
          .firestore()
          .collection('subscriptions')
          .orderBy('createdAt', 'desc')
          .get()
          .then((subs) => {
            const subscpts = subs.docs.map((s) => {
              const subData = s.data() as SubscriptionData
              const subPsp = psps.find((p) => p.id === subData.pspId)
              return {
                ...subData,
                id: s.id,
                psp: subPsp,
              }
            })
            firebaseApp.app
              .firestore()
              .collection('serviceActivations')
              .orderBy('createdAt', 'desc')
              .get()
              .then((srvAct) => {
                const srvActivations = srvAct.docs.map((sa) => {
                  const serviceActivationData = sa.data() as ServiceActivationData
                  const saSub = subscpts.find((s) => s.id === serviceActivationData.subscriptionId)
                  return {
                    ...serviceActivationData,
                    id: sa.id,
                    subscription: saSub,
                    psp: saSub?.psp,
                  }
                })
                setServiceActivations(srvActivations)
                setLoading(false)
              })
          })
      })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" css={{ marginBottom: 16 }}>
          Elenco Attivazioni Servizi
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Data</TableCell>
              <TableCell align="right">Codice Adesione</TableCell>
              <TableCell align="right">Comune Paziente</TableCell>
              <TableCell align="right">PSP</TableCell>
              <TableCell align="right">Nome Medico</TableCell>
              <TableCell align="right">Centro</TableCell>
              <TableCell align="right">Cellulare</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Provincia</TableCell>
              <TableCell align="right">Città</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceActivations.map((sa) => (
              <TableRow key={sa.id}>
                <TableCell align="right">{format(sa.createdAt.toDate(), 'dd/MM/Y HH:mm')}</TableCell>
                <TableCell align="right">{sa.id}</TableCell>
                <TableCell align="right">{sa.patientLocality || '-'}</TableCell>
                <TableCell align="right">{sa.psp ? sa.psp.shortName : 'Non trovato'}</TableCell>
                <TableCell align="right">{`${sa.subscription.title} ${sa.subscription.lastName} ${sa.subscription.firstName}`}</TableCell>
                <TableCell align="right">{sa.subscription.center}</TableCell>
                <TableCell align="right">{sa.subscription.mobile}</TableCell>
                <TableCell align="right">{sa.subscription.email}</TableCell>
                <TableCell align="right">{sa.subscription.centerProvince}</TableCell>
                <TableCell align="right">{sa.subscription.centerCity}</TableCell>
                <TableCell align="right">
                  <ServiceActivationFormPDFDownloadButtonContainer serviceActivation={sa} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ServiceActivationsTable
