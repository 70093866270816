import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfigStaging = {
  apiKey: 'AIzaSyC9Ic3ucwZuPV4GVDvC-MGTrm12oLJSoMk',
  authDomain: 'takepart-staging.firebaseapp.com',
  databaseURL: 'https://takepart-staging.firebaseio.com',
  projectId: 'takepart-staging',
  storageBucket: 'takepart-staging.appspot.com',
  messagingSenderId: '142135051430',
  appId: '1:142135051430:web:aa3be83d459127dca06067',
}

const firebaseConfigProduction = {
  apiKey: 'AIzaSyDz2PaEsHvDyw6rl0BhR3DU-6ZyJqdmVcY',
  authDomain: 'takepart-production.firebaseapp.com',
  databaseURL: 'https://takepart-production.firebaseio.com',
  projectId: 'takepart-production',
  storageBucket: 'takepart-production.appspot.com',
  messagingSenderId: '380732242076',
  appId: '1:380732242076:web:1270fbe4e51a195ef35559',
}

let firebaseConfig: Object

if (process.env.REACT_APP_ENV === 'production') {
  firebaseConfig = firebaseConfigProduction
} else {
  firebaseConfig = firebaseConfigStaging
}

class App {
  app: firebase.app.App

  constructor() {
    this.app = firebase.initializeApp(firebaseConfig)
  }
}

let app: App | undefined = undefined

if (!app) {
  app = new App()
}

export default app as App
