import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core'
import firebaseApp from '../firebase'

const NavBar: React.FC<{}> = () => {
  const location = useLocation()
  const history = useHistory()

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6">TakePart Admin</Typography>
        <Button
          color="inherit"
          css={{
            marginLeft: 64,
            textDecoration: ['/', '/subscriptions'].includes(location.pathname) ? 'underline' : undefined,
          }}
          onClick={() => {
            history.push('/subscriptions')
          }}
        >
          Adesioni Medici
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            history.push('/service-activations')
          }}
          css={{
            textDecoration: location.pathname === '/service-activations' ? 'underline' : undefined,
          }}
        >
          Attivazioni Servizi
        </Button>
        <Button color="inherit" css={{ marginLeft: 'auto' }} onClick={logout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  )
}

function logout() {
  firebaseApp.app.auth().signOut()
}

export default NavBar
