import React from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MiciActivationFormPDF from './mici-activation-form-pdf'
import HaemoActivationFormPDF from './haemo-activation-form-pdf'
import ImmunoActivationFormPDF from './immuno-activation-form-pdf'
import SBSActivationFormPDF from './sbs-activation-form-pdf'
import IpoActivationFormPDF from './ipo-activation-form-pdf'

interface Props {
  serviceActivation: any
}

const ServiceActivationFormPDFDownloadButton: React.FC<Props> = ({ serviceActivation }) => {
  const pdfDownloadButtonBaseProps = {
    fileName: `${serviceActivation.id}`,
    css: downloadButtonStyle,
  }

  const pdfDownloadButtonDocumentProps = {
    activationPsp: serviceActivation.psp,
    createdAt: serviceActivation.createdAt.toDate(),
    serviceActivationId: serviceActivation.id,
  }

  switch (serviceActivation.psp.shortName) {
    case 'MICI':
      return (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={<MiciActivationFormPDF {...pdfDownloadButtonDocumentProps} serviceActivation={serviceActivation} />}
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
    case 'Ematologia':
      return (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <HaemoActivationFormPDF {...pdfDownloadButtonDocumentProps} serviceActivation={serviceActivation} />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
    case 'Immunologia':
      return (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={
            <ImmunoActivationFormPDF {...pdfDownloadButtonDocumentProps} serviceActivation={serviceActivation} />
          }
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
    case 'SBS':
      return (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={<SBSActivationFormPDF {...pdfDownloadButtonDocumentProps} serviceActivation={serviceActivation} />}
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
    case 'Ipoparatiroidismo':
      return (
        <PDFDownloadLink
          {...pdfDownloadButtonBaseProps}
          document={<IpoActivationFormPDF {...pdfDownloadButtonDocumentProps} serviceActivation={serviceActivation} />}
        >
          {({ loading }) => (loading ? 'Caricamento della scheda...' : 'Scarica la scheda')}
        </PDFDownloadLink>
      )
    default:
      return null
  }
}

const downloadButtonStyle = {
  backgroundColor: '#fbb600',
  borderRadius: 5,
  padding: '4px 8px',
  color: '#fff',
  textDecoration: 'none',
  fontSize: 18,
  margin: '12px auto',
  '&:hover': {
    backgroundColor: '#fba600',
  },
}

export default ServiceActivationFormPDFDownloadButton
