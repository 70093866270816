import React from 'react'
import app from './firebase'
import firebase from 'firebase'
import Loading from './components/loading'
import AdminPage from './components/admin-page'
import LoginPage from './components/login-page'

interface State {
  user: firebase.User | null
  loading: boolean
  authorized: boolean
}

const Auth: React.FC<{}> = () => {
  const [state, setState] = React.useState<State>({ user: null, loading: true, authorized: false })
  const [error, setError] = React.useState<string | null>(null)

  const setUser = React.useCallback((user: firebase.User | null) => {
    if (user) {
      user.getIdTokenResult(true).then((idTokenResult) => {
        setState({ loading: false, user: user, authorized: !!idTokenResult.claims.authorized })
      })
    } else {
      setState({ loading: false, user: user, authorized: false })
    }
  }, [])

  React.useEffect(() => {
    app.app.auth().onAuthStateChanged((u) => {
      setUser(u)
    })
  }, [setUser])

  React.useEffect(() => {
    if (!state.loading && !state.user) {
      const provider = new firebase.auth.GoogleAuthProvider()
      app.app
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          setUser(result.user)
        })
        .catch((error) => {
          setError(error.code)
        })
    }
  }, [setUser, state.loading, state.user])

  if (state.loading) {
    return <Loading />
  } else if (state.user && state.authorized) {
    return <AdminPage />
  } else if (error) {
    return <LoginPage error={error} />
  } else {
    return <LoginPage />
  }
}

export default Auth
