import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import firebaseApp from '../firebase'
import Loading from './loading'
import { FormikHelpers, useFormik } from 'formik'
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@material-ui/core'
import { object, string, boolean } from 'yup'

const validationSchema = object({
  email: string().email().required(),
  enabled: boolean().required(),
})

interface FormValues {
  email: string
  enabled: boolean
}

interface SubscriptionServiceActivationConfigurationData {
  email: string
  enabled: boolean
}
interface SubscriptionServiceActivationConfiguration extends SubscriptionServiceActivationConfigurationData {
  id: string
}

const SubscriptionServiceActivationConfiguration: React.FC<{}> = () => {
  const { subscriptionId }: { subscriptionId: string } = useParams()
  const history = useHistory()
  const [error, setError] = React.useState<string | null>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [configuration, setConfiguration] = React.useState<SubscriptionServiceActivationConfiguration | null>()

  let initialValues: FormValues
  if (configuration) {
    initialValues = {
      email: configuration.email,
      enabled: configuration.enabled,
    }
  } else {
    initialValues = {
      email: '',
      enabled: true,
    }
  }

  const handleFormSubmit = React.useCallback(
    (values: FormValues, helpers: FormikHelpers<FormValues>) => {
      if (configuration) {
        firebaseApp.app
          .firestore()
          .collection('subscriptionServiceActivationConfigurations')
          .doc(configuration.id)
          .update(values)
          .then(() => {
            helpers.setSubmitting(false)
            history.push('/')
          })
          .catch((error) => {
            setError('Impossibile aggiornare, il server ha restituito un errore: ' + JSON.stringify(error))
            helpers.setSubmitting(false)
          })
      } else {
        setError('Impossibile aggiornare, Id della configurazione non presente')
      }
    },
    [configuration, history]
  )

  const { getFieldProps, handleSubmit, values, errors } = useFormik<FormValues>({
    initialValues,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    validationSchema,
  })

  React.useEffect(() => {
    firebaseApp.app
      .firestore()
      .collection('subscriptionServiceActivationConfigurations')
      .where('subscriptionId', '==', subscriptionId)
      .get()
      .then((config) => {
        const configurations: SubscriptionServiceActivationConfiguration[] = config.docs.map((c) => ({
          ...(c.data() as SubscriptionServiceActivationConfigurationData),
          id: c.id,
        }))

        if (configurations.length === 0) {
          setError('Configurazione non trovata')
          setLoading(false)
        } else if (configurations.length > 1) {
          setError('Trovate troppe configurazioni')
          setLoading(false)
        } else {
          setConfiguration(configurations[0])
          setLoading(false)
        }
      })
  }, [subscriptionId])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div>
      <Typography css={{ marginBottom: 16 }} variant="h3">
        Configurazioni Medico
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          {...getFieldProps('email')}
          label="Email"
          required
          error={!!errors.email}
          helperText={errors.email}
          css={{ marginBottom: 16 }}
        />
        <FormGroup css={{ marginBottom: 16 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('enabled')} checked={values.enabled} />}
            label="Medico abilitato ad attivare servizi"
          />
        </FormGroup>
        <Button variant="contained" color="primary" type="submit">
          Salva
        </Button>
      </form>
    </div>
  )
}

export default SubscriptionServiceActivationConfiguration
