import React from 'react'
import { Typography } from '@material-ui/core'

const Loading: React.FC<{}> = () => {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
      }}
    >
      <Typography variant="h2">Loading...</Typography>
    </div>
  )
}

export default Loading
