import React from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer'
import { styles } from './pdf-form-styles'

interface Props {
  serviceActivation: any
  serviceActivationId: string
  activationPsp: any
  createdAt: Date
}

const HaemoActivationFormPDF: React.FC<Props> = ({
  serviceActivationId,
  serviceActivation,
  activationPsp,
  createdAt,
}) => (
  <Document>
    <Page style={styles.body}>
      <View>
        <Text style={styles.title}>PROGRAMMA DI SUPPORTO AL PAZIENTE IN {activationPsp.shortName.toUpperCase()}</Text>
        <Text style={styles.undertitle}>Scheda di Attivazione dei Servizi</Text>
        <Text style={styles.text}>
          La presente scheda è stata compilata dal Medico Specialista e trasmessa ad Egg S.r.l. al fine di consentire
          l’attivazione dei Servizi per i Pazienti che aderiscono al {activationPsp.name}.
        </Text>
      </View>
      <View style={{ textAlign: 'center', marginTop: 4, marginBottom: 4 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
          Codice attivazione: <Text>{serviceActivationId}</Text>
        </Text>
        {serviceActivation.patientLocality !== '' && (
          <Text style={{ fontWeight: 'bold', fontSize: 14 }}>
            Comune domicilio Paziente: <Text>{serviceActivation.patientLocality}</Text>
          </Text>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di training all'autosomministrazione:{' '}
          {serviceActivation.trainingService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.trainingService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Propensione del Paziente (o del suo Caregiver) a diventare autonomo nell’auto somministrazione
                endovenosa di fattori della coagulazione:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.probabilityOfTrainingSuccess || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Indicare eventuali fattori che potrebbero influire positivamente o negativamente sulla capacità del
                Paziente o del caregiver di raggiungere un buon livello di autonomia entro 3 mesi dall’inizio{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.trainingNote || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Si richiede di poter avere un confronto diretto con il Medico di Servizio di Egg:{' '}
              </Text>
              <Text style={styles.value}>
                {serviceActivation.requestForContactWithServicePhysicianForTraining
                  ? `Si, richiesto un ${serviceActivation.typeOfContactWithServicePhysicianForTraining}`
                  : 'No'}
              </Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Desidera essere contattato telefonicamente dopo la prima attività al domicilio del Paziente per ricevere
                un feedback dall’Infermiere?{' '}
              </Text>
              <Text style={styles.value}>
                {serviceActivation.requestForAPhoneContactAfterFirstTraining ? 'Si' : 'No'}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di somministrazione:{' '}
          {serviceActivation.infusionService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.infusionService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>
                Si richiede di poter avere un confronto diretto con il Medico di Servizio di Egg{' '}
              </Text>
              <Text style={styles.value}>
                {serviceActivation.requestForContactWithServicePhysicianForInfusion
                  ? `Si, desidero un ${serviceActivation.typeOfContactWithServicePhysicianForInfusion}`
                  : 'No'}
              </Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Desidera essere contattato telefonicamente dopo la prima attività al domicilio del Paziente per ricevere
                un feedback dall’Infermiere?{' '}
              </Text>
              <Text style={styles.value}>
                {serviceActivation.requestForAPhoneContactAfterFirstInfusion ? 'Si' : 'No'}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di supporto fisioterapico:{' '}
          {serviceActivation.physiotherapyService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.physiotherapyService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Presenza di artropatia: </Text>
              <Text style={styles.value}>{serviceActivation.presenceOfArthropathy ? 'Si' : 'No'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Indicare le articolazioni bersaglio: </Text>
              <Text style={styles.value}>{`${serviceActivation.targetJoinsAnkle ? 'Caviglia -' : ''} ${
                serviceActivation.targetJoinsKnee ? 'Ginocchio -' : ''
              } ${serviceActivation.targetJoinsElbow ? 'Gomito -' : ''} ${
                serviceActivation.targetJoinsOther ? 'Altro: ' + serviceActivation.targetJoinsOtherSpecify : ''
              }`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Fornire una breve relazione indicando la presenza di eventuali emartri, sanguinamenti o eventi che
                possono influire nello svolgimento dell’attività fisioterapica sul Paziente:{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.physiotherapyInformations || '-'}</Text>
            </View>

            {serviceActivation.physiotherapyInformations !== '' && (
              <View style={styles.field}>
                <Text style={styles.label}>
                  Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso del farmaco indicato nella
                  sezione “Piano Terapeutico”:{' '}
                </Text>
                <Text style={styles.value}>{serviceActivation.physiotherapyInformationsPreTherapy ? 'Si' : 'No'}</Text>
              </View>
            )}

            <View style={styles.field}>
              <Text style={styles.label}>Tipo di fisioterapia richiesta: </Text>
              <Text style={styles.value}>{`${
                serviceActivation.typeOfRequestedPhysiotherapyPostSurgery ? 'Post-Chirurgica -' : ''
              } ${serviceActivation.typeOfRequestedPhysiotherapyMaintenance ? 'Mantenimento -' : ''} ${
                serviceActivation.typeOfRequestedPhysiotherapyProprioceptive ? 'Propriocettiva' : ''
              }`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Articolazioni da trattare: </Text>
              <Text style={styles.value}>{`${serviceActivation.jointsToTargetAnkle ? 'Caviglia -' : ''} ${
                serviceActivation.jointsToTargetKnee ? 'Ginocchio -' : ''
              } ${serviceActivation.jointsToTargetElbow ? 'Gomito -' : ''} ${
                serviceActivation.jointsToTargetOther ? 'Altro: ' + serviceActivation.jointsToTargetOtherSpecify : ''
              }`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Scopo ed obiettivi dell’attività fisioterapica richiesta: </Text>
              <Text style={styles.value}>{serviceActivation.physiotherapyGoals || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Durata del trattamento (in mesi): </Text>
              <Text style={styles.value}>{serviceActivation.physiotherapyDuration || '-'}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Frequenza accessi settimanali: </Text>
              <Text style={styles.value}>{serviceActivation.visitsFrequency || '-'}</Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di prelievo ematico per il monitoraggio della terapia:{' '}
          {serviceActivation.bloodWithdrawalService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.bloodWithdrawalService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Data e ora del 1° prelievo: </Text>
              <Text style={styles.value}>{`${serviceActivation.firstWithdrawalDateAndTime || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Data e ora del 2° prelievo: </Text>
              <Text style={styles.value}>{`${serviceActivation.secondWithdrawalDateAndTime || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Data e ora del 3° prelievo: </Text>
              <Text style={styles.value}>{`${serviceActivation.thirdWithdrawalDateAndTime || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Data e ora del 4° prelievo: </Text>
              <Text style={styles.value}>{`${serviceActivation.fourthWithdrawalDateAndTime || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Data e ora del 5° prelievo: </Text>
              <Text style={styles.value}>{`${serviceActivation.fifthWithdrawalDateAndTime || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Indicare eventuali indicazioni aggiuntive (indluso prelievi oltre i 5): </Text>
              <Text style={styles.value}>{`${serviceActivation.withdrawalSchemeNote || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Indicare il tempo massimo che deve trascorrere dal prelievo alla consegna del campione ematico al
                Laboratorio{' '}
              </Text>
              <Text style={styles.value}>{`${serviceActivation.maxTimeBetweenWithdrawalAndDelivery || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>
                Il Laboratorio è stato informato dell’attivazione del Servizio per il Paziente?{' '}
              </Text>
              <Text style={styles.value}>{`${serviceActivation.labHasBeenInformed || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Referente del Laboratorio: </Text>
              <Text style={styles.value}>{`${serviceActivation.labContact || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Recapito telefonico del referente del Laboratorio: </Text>
              <Text style={styles.value}>{`${serviceActivation.labContactPhone || '-'}`}</Text>
            </View>

            <View style={styles.field}>
              <Text style={styles.label}>Note: </Text>
              <Text style={styles.value}>{`${serviceActivation.withdrawalNote || '-'}`}</Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>
          Servizio di consegna domiciliare del farmaco:{' '}
          {serviceActivation.drugDeliveryService ? (
            <Text style={styles.serviceStatusActivated}>attivato</Text>
          ) : (
            <Text style={styles.serviceStatusNotActivated}>non attivato</Text>
          )}
        </Text>
        {serviceActivation.drugDeliveryService && (
          <View>
            <View style={styles.field}>
              <Text style={styles.label}>Farmacia per il ritiro: </Text>
              <Text style={styles.value}>
                {serviceActivation.pharmacyOptions === 'Gli estremi della Farmacia sono i seguenti'
                  ? serviceActivation.pharmacyInformations
                  : serviceActivation.pharmacyOptions}
              </Text>
            </View>
          </View>
        )}
      </View>

      <View style={styles.section}>
        <Text style={styles.subtitle}>Informazioni sul Piano Terapeutico</Text>
        <View>
          <View style={styles.field}>
            <Text style={styles.label}>Farmaco: </Text>
            <Text style={styles.value}>{serviceActivation.drugName || '-'}</Text>
          </View>

          {(serviceActivation.trainingService ||
            serviceActivation.infusionService ||
            serviceActivation.physiotherapyService) && (
            <View style={styles.field}>
              <Text style={styles.label}>Numero di somministrazioni settimanali: </Text>
              <Text style={styles.value}>{serviceActivation.infusionFrequency || '-'}</Text>
            </View>
          )}

          {(serviceActivation.trainingService ||
            serviceActivation.infusionService ||
            serviceActivation.physiotherapyService) && (
            <View style={styles.field}>
              <Text style={styles.label}>Dosaggio: </Text>
              <Text style={styles.value}>{serviceActivation.dose || '-'}</Text>
            </View>
          )}

          {(serviceActivation.trainingService ||
            serviceActivation.infusionService ||
            serviceActivation.physiotherapyService ||
            serviceActivation.bloodWithdrawalService) && (
            <View style={styles.field}>
              <Text style={styles.label}>Modalità di infusione: </Text>
              <Text style={styles.value}>
                {serviceActivation.infusionType === 'Endovena'
                  ? 'Endovena'
                  : 'Altro - ' + serviceActivation.infusionTypeOther}
              </Text>
            </View>
          )}

          {(serviceActivation.trainingService || serviceActivation.infusionService) && (
            <View style={styles.field}>
              <Text style={styles.label}>Velocità di infusione (se differente dall'RCP di prodotto): </Text>
              <Text style={styles.value}>{serviceActivation.infusionSpeed || '-'}</Text>
            </View>
          )}

          {(serviceActivation.trainingService ||
            serviceActivation.infusionService ||
            serviceActivation.physiotherapyService ||
            serviceActivation.bloodWithdrawalService) && (
            <View style={styles.field}>
              <Text style={styles.label}>Indicazioni sanitarie: </Text>
              <Text style={styles.value}>{serviceActivation.sanitaryInformations || '-'}</Text>
            </View>
          )}

          {serviceActivation.sanitaryInformations !== '' && (
            <View style={styles.field}>
              <Text style={styles.label}>
                Le indicazioni sanitarie fornite sono antecedenti e non correlate all’uso del farmaco indicato nella
                sezione "Piano Terapeutico"{' '}
              </Text>
              <Text style={styles.value}>{serviceActivation.sanitaryInformationsPreTherapy ? 'Si' : 'No'}</Text>
            </View>
          )}
        </View>
      </View>

      <View style={styles.fieldNoSection}>
        <Text style={styles.label}>
          Richiedo di essere informato via email quando il Paziente completerà l'adesione:
        </Text>
        <Text style={styles.value}>{serviceActivation.requestFollowupOnPatientEnrollment ? 'Si' : 'No'}</Text>
      </View>

      <View>
        <Text style={styles.text}>
          Io sottoscritto/a, in qualità di Medico Specialista iscritto al {activationPsp.name}, ricevuta richiesta da
          parte del mio Paziente di attivare i servizi inclusi nel Programma, confermo che il Paziente è idoneo a
          ricevere i servizi qui selezionati secondo quanto previsto dalla Scheda Tecnica di Prodotto ed alle
          indicazioni sopra fornitevi. Sono inoltre consapevole che i servizi sopra indicati possono essere attivati
          solo per i Pazienti che hanno già iniziato la terapia con {serviceActivation.drugName} e che tali servizi sono
          da considerarsi come addizionali e non sostitutivi a quelli in capo all'Ente o al SSN.
        </Text>
      </View>
      <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        <Text style={styles.text}>
          Data di compilazione: {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
        </Text>
      </View>
    </Page>
  </Document>
)

export default HaemoActivationFormPDF
