import React from 'react'
import { Paper, Typography } from '@material-ui/core'

interface Props {
  error?: string
}

const LoginPage: React.FC<Props> = ({ error }) => {
  return (
    <div
      css={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper css={{ padding: 16, maxWidth: '60%' }}>
        <Typography variant="h6" css={{ marginBottom: 16 }}>
          Effettuare il login dal popup che si apre automaticamente al caricamento della pagina
        </Typography>
        <Typography variant="body1" color="primary" css={{ marginBottom: 16 }}>
          Se il popup non dovesse comparire o se dovesse essere chiuso per errore ricaricare la pagina
        </Typography>
        {error && (
          <Typography variant="subtitle1" color="secondary">
            Errore: {error}
          </Typography>
        )}
      </Paper>
    </div>
  )
}

export default LoginPage
