import React from 'react'
import NavBar from './nav-bar'
import { Grid } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'
import SubscriptionTable from './subscription-table'
import SubscriptionServiceActivationConfiguration from './subscription-service-activation-configuration'
import ServiceActivationsTable from './service-activations-table'

const AdminPage: React.FC<{}> = () => {
  return (
    <div>
      <NavBar />
      <div css={{ padding: 32 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Switch>
              <Route exact path="/">
                <SubscriptionTable />
              </Route>
              <Route exact path="/subscriptions">
                <SubscriptionTable />
              </Route>
              <Route exact path="/service-activations">
                <ServiceActivationsTable />
              </Route>
              <Route exact path="/subscription-service-activation-configuration/:subscriptionId">
                <SubscriptionServiceActivationConfiguration />
              </Route>
              <Route>
                <div css={{ fontSize: 24, color: 'red', fontWeight: 'bold' }}>
                  Error, the page you are searching can't be found
                </div>
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default AdminPage
