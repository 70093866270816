import React from 'react'
import ServiceActivationFormPDFDownloadButton from './service-activation-form-pdf-download-button'

interface Props {
  serviceActivation: any
}

const ServiceActivationFormPDFDownloadButtonContainer: React.FC<Props> = ({ serviceActivation }) => {
  const [clicked, setClicked] = React.useState<boolean>(false)

  if (!clicked) {
    return (
      <button
        css={downloadButtonStyle}
        onClick={() => {
          setClicked(true)
        }}
      >
        Genera la scheda
      </button>
    )
  } else {
    return <ServiceActivationFormPDFDownloadButton serviceActivation={serviceActivation} />
  }
}

const downloadButtonStyle = {
  backgroundColor: '#fbb600',
  borderRadius: 5,
  padding: '4px 8px',
  color: '#fff',
  textDecoration: 'none',
  fontSize: 18,
  margin: '12px auto',
  '&:hover': {
    backgroundColor: '#fba600',
  },
}

export default ServiceActivationFormPDFDownloadButtonContainer
