import React from 'react'
import { css, Global } from '@emotion/core'
import emotionReset from 'emotion-reset'
import Auth from './auth'

function App() {
  return (
    <div>
      <Global
        styles={css`
          ${emotionReset}
        `}
      />
      <Auth />
    </div>
  )
}

export default App
