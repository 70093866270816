import React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { Table, TableContainer, TableHead, Paper, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'
import firebaseApp from '../firebase'
import Loading from './loading'
import ExcelExportDownload, { generateServiceActivationURL } from './excel-export-download'
import { Psp, PspData, Subscription, SubscriptionData } from '../types'

const SubscriptionTable: React.FC<{}> = () => {
  const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    firebaseApp.app
      .firestore()
      .collection('psps')
      .get()
      .then((psp) => {
        const psps: Psp[] = psp.docs.map((p) => ({ ...(p.data() as PspData), id: p.id }))
        firebaseApp.app
          .firestore()
          .collection('subscriptions')
          .orderBy('createdAt', 'desc')
          .get()
          .then((subs) => {
            const subscpts = subs.docs.map((s) => {
              const subData = s.data() as SubscriptionData
              const subPsp = psps.find((p) => p.id === subData.pspId)
              return {
                ...subData,
                id: s.id,
                psp: subPsp,
              }
            })
            setSubscriptions(subscpts)
            setLoading(false)
          })
      })
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" css={{ marginBottom: 16 }}>
          Elenco Adesioni
        </Typography>
        <ExcelExportDownload subscriptions={subscriptions} />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Config</TableCell>
              <TableCell align="right">Data</TableCell>
              <TableCell align="right">PSP</TableCell>
              <TableCell align="right">Nome</TableCell>
              <TableCell align="right">Ruolo</TableCell>
              <TableCell align="right">Centro</TableCell>
              <TableCell align="right">Reparto</TableCell>
              <TableCell align="right">Cellulare</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Orario Contatto</TableCell>
              <TableCell align="right">Note Contatto</TableCell>
              <TableCell align="right">Provincia</TableCell>
              <TableCell align="right">Città</TableCell>
              <TableCell align="right">Cap</TableCell>
              <TableCell align="right">Via</TableCell>
              <TableCell align="right">Consenso E</TableCell>
              <TableCell align="right">Consenso F</TableCell>
              <TableCell align="right">Service Activation URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptions.map((sub) => (
              <TableRow key={sub.id}>
                <TableCell align="left">
                  <Link to={`/subscription-service-activation-configuration/${sub.id}`}>Config</Link>
                </TableCell>
                <TableCell align="right">{format(sub.createdAt.toDate(), 'dd/MM/Y HH:mm')}</TableCell>
                <TableCell align="right">{sub.psp ? sub.psp.shortName : 'Non trovato'}</TableCell>
                <TableCell align="right">{`${sub.title} ${sub.lastName} ${sub.firstName}`}</TableCell>
                <TableCell align="right">{sub.role}</TableCell>
                <TableCell align="right">{sub.center}</TableCell>
                <TableCell align="right">{sub.ward}</TableCell>
                <TableCell align="right">{sub.mobile}</TableCell>
                <TableCell align="right">{sub.email}</TableCell>
                <TableCell align="right">{sub.phoneTimePreference}</TableCell>
                <TableCell align="right">{sub.phoneTimeExtraInformations}</TableCell>
                <TableCell align="right">{sub.centerProvince}</TableCell>
                <TableCell align="right">{sub.centerCity}</TableCell>
                <TableCell align="right">{sub.centerCap}</TableCell>
                <TableCell align="right">{sub.centerStreet}</TableCell>
                <TableCell align="right">{sub.letterEConsent === 'given' ? 'Acconsento' : 'Non Acconsento'}</TableCell>
                <TableCell align="right">{sub.letterFConsent === 'given' ? 'Acconsento' : 'Non Acconsento'}</TableCell>
                <TableCell align="right">
                  <a href={generateServiceActivationURL(sub.id)}>{generateServiceActivationURL(sub.id)}</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SubscriptionTable
